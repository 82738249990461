.content-truba_tool {
  width: 100%;
  background-image: url(../images/trubaBack.png);
  background-repeat: no-repeat;
  margin-bottom: 30px;
  min-width: 1362px;
  padding: 0px; }
  .content-truba_tool .content-text {
    width: 1097px;
    color: white;
    font-size: 17px;
    line-height: 23px;
    margin: 0 auto; }
    .content-truba_tool .content-text__upper {
      margin-bottom: 36px;
      line-height: 30px; }
    .content-truba_tool .content-text__bottom {
      display: flex;
      padding-bottom: 10px; }
      .content-truba_tool .content-text__bottom a {
        color: white;
        text-decoration: none;
        font-size: 20px;
        line-height: 23px;
        padding: 7px 20px;
        border-radius: 3px; }
        .content-truba_tool .content-text__bottom a span {
          font-size: 16px;
          line-height: 19px;
          color: #69738C;
          margin-left: 10px; }
        .content-truba_tool .content-text__bottom a.active {
          background: #0288D1; }
          .content-truba_tool .content-text__bottom a.active span {
            color: #78C2EB; }
  .content-truba_tool.sosed .content-text {
    font-size: 17px;
    line-height: 20px; }
    .content-truba_tool.sosed .content-text__upper {
      line-height: 26px;
      font-size-adjust: 0.5; }

.truba-wrapper {
  padding: 0px 5px;
  margin-bottom: 100px; }

.sosed-wrapper {
  padding: 0px 5px;
  margin: 0 auto 20px;
  width: 1093px; }
  .sosed-wrapper_header {
    height: 40px;
    margin: 0 auto 20px;
    font-size: 24px;
    line-height: 28px;
    color: #363C4C; }
    .sosed-wrapper_header .sosed-create_button {
      background: #0288D1;
      color: white;
      border: 0;
      outline: 0;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      width: 140px;
      height: 40px;
      border-radius: 3px;
      margin-left: 21px;
      cursor: pointer; }
      .sosed-wrapper_header .sosed-create_button:hover {
        background: #0078BA; }
  .sosed-wrapper.my-sosed {
    margin-bottom: 100px; }

.items-grid.tools .items-name {
  display: block;
  margin-bottom: 3px; }

.items-grid.tools .items-user,
.items-grid.tools .items-date,
.items-grid.tools .items-views {
  display: inline-block;
  color: #69738C;
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.items-grid.tools .items-user {
  max-width: 60px; }

.items-grid.tools .items-date {
  max-width: 135px; }

.items-grid.tools .items-views {
  max-width: 135px;
  margin-right: 0; }

.content-dtp__linker {
  width: 1362px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0 auto 30px; }
  .content-dtp__linker a {
    text-decoration: none;
    color: #69738C;
    margin-left: 60px; }
    .content-dtp__linker a:first-child {
      margin-left: 0; }
    .content-dtp__linker a span {
      margin-left: 10px;
      font-size: 13px; }
    .content-dtp__linker a.active {
      color: #363C4C; }

.mobile-wrapper.public-wrapper .items-grid__item {
  margin-bottom: 60px; }
  .mobile-wrapper.public-wrapper .items-grid__item:last-child {
    margin-bottom: 7em; }
  .mobile-wrapper.public-wrapper .items-grid__item .items-grid__item_arcLink {
    position: absolute;
    width: initial !important;
    max-width: initial !important;
    left: 0px; }
    .mobile-wrapper.public-wrapper .items-grid__item .items-grid__item_arcLink.display {
      left: 85px; }

.mobile-wrapper .items-grid__item {
  position: relative; }
  .mobile-wrapper .items-grid__item:last-child {
    margin-bottom: 60px; }
  .mobile-wrapper .items-grid__item .items-grid__item_link {
    color: #69738C; }
    .mobile-wrapper .items-grid__item .items-grid__item_link .items-date {
      float: left;
      margin: 5px 0 40px 15px; }
    .mobile-wrapper .items-grid__item .items-grid__item_link .items-views {
      float: right;
      margin: 5px 15px 40px 0; }
