.quick-access-for-ur .header {
  font-family: 'Roboto', sans-serif;
  display: block;
  color: #363c4c;
  margin-bottom: 1rem; }

.content-wrapper.quick-access-for-ur .header {
  font-size: 24px;
  line-height: 28px;
  text-align: left; }

.content-wrapper.quick-access-for-ur .video-player {
  min-height: 450px; }

.mobile-wrapper.quick-access-for-ur .header {
  font-size: 21px;
  line-height: 23px;
  text-align: center; }

.mobile-wrapper.quick-access-for-ur .mobile-video-player {
  height: 220px; }
