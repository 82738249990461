.wbbanner__layout {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.wbbanner {
  position: relative;
  max-width: 800px;
  max-height: 400px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 40px;

  font-size: 22px;
}

.wbbanner__logo {
  background-image: url("../images/techWorksPlug/logo.svg");
  width:308px;
  height: 198px;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.wbbanner__logo svg {
  width: 100%;
  height: auto;
}

.wbbanner__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  background-image: url("../images/techWorksPlug/closer.svg");
}

.wbbanner__close svg {
  width: 100%;
  height: auto;
}

.wbbanner__title {
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}

.wbbanner__p {
  text-align: center;
}

@media (max-width: 767px) {
  .wbbanner {
    font-size: 18px;
    max-width: 70%; //70
    max-height: 60%;
  }
  .wbbanner__logo {
    max-width: 180px;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-30%);
  }
  .wbbanner__p {
    margin-bottom: 25px;
    width: 106%;
  }
}