.domofon {
  font-family: 'Roboto', sans-serif;
  color: #323232;
  background-color: #fff; }
  .domofon .header {
    margin-top: 74px; }
    .domofon .header__title h1 {
      margin: 0;
      font-weight: bold;
      font-size: 34px;
      line-height: 141%; }
    .domofon .header__logo {
      display: flex;
      justify-content: flex-end; }
      .domofon .header__logo a svg {
        width: 270px;
        height: 34px; }
    .domofon .header__description {
      margin-top: 32px;
      font-weight: 400;
      font-size: 20px;
      line-height: 141%; }
    .domofon .header__connect-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .domofon .header__connect-btn {
      width: 270px;
      height: 60px;
      border-radius: 10px;
      background: #1976d2;
      color: white;
      font-size: 20px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      cursor: pointer; }
  .domofon .about {
    margin-top: 58px; }
    .domofon .about__row {
      display: flex;
      gap: 81px;
      width: 100%; }
    .domofon .about__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 270px; }
      .domofon .about__item-img {
        width: 270px;
        height: 341px; }
      .domofon .about__item-description {
        width: 221px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center; }
  .domofon .application {
    margin-top: 65px; }
    .domofon .application__row {
      display: flex;
      gap: 100px; }
    .domofon .application__form {
      display: flex;
      flex-direction: column;
      width: 670px;
      border: 1px solid #CBD6E1;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 39px 100px 49px 100px; }
      .domofon .application__form-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px; }
      .domofon .application__form input, .domofon .application__form select {
        display: block;
        width: 100%;
        height: 60px;
        background: #E8F2FC;
        border-radius: 5px;
        outline: none;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #323232;
        padding: 20px;
        margin-bottom: 19px;
        transition: box-shadow .2s; }
      .domofon .application__form input:focus, .domofon .application__form select:focus {
        background: #fff;
        box-shadow: 0 0 0 2px #a1cbef; }
      .domofon .application__form input::placeholder {
        color: #323232; }
      .domofon .application__form select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../images/domofon/selectArrow.svg");
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 50%; }
      .domofon .application__form-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-top: 16px;
        margin-bottom: 32px; }
      .domofon .application__form-submit {
        width: 211px;
        height: 60px;
        background: #1976D2;
        border-radius: 10px;
        color: #fff;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        cursor: pointer; }
      .domofon .application__form .input-error {
        box-shadow: 0 0 0 1px red;
        border-radius: 5px; }
    .domofon .application__img {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .domofon .application__img-first {
        width: 310px;
        height: 233px; }
      .domofon .application__img-second {
        width: 310px;
        height: 233px; }
    .domofon .application__note {
      margin-top: 56px;
      overflow: hidden;
      transition: .2s; }
      .domofon .application__note-btn {
        position: relative;
        border: none;
        padding: 0 0 3px 0;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #9FACB8;
        background-color: transparent;
        border-bottom: 1px dashed #9FACB8; }
      .domofon .application__note-content {
        padding: 10px 0; }
  .domofon .safety {
    margin-top: 85px;
    border-radius: 20px;
    border: 1px solid #cbd6e1;
    padding: 54px 46px 51px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 60px; }
    .domofon .safety__header {
      margin: 0;
      font-size: 31px;
      font-weight: 700;
      text-align: center;
      color: #323232; }
    .domofon .safety__list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px; }
    .domofon .safety__list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 25px;
      max-width: 186px; }
      .domofon .safety__list-item_big {
        max-width: 239px; }
    .domofon .safety__list-image {
      width: 120px;
      height: 120px; }
    .domofon .safety__list-text {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      color: #323232; }
  .domofon .footer {
    height: 180px;
    margin-top: 30px; }
    .domofon .footer__row {
      display: flex;
      gap: 46px; }
    .domofon .footer__item {
      display: flex;
      gap: 13px; }
      .domofon .footer__item-call {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #323232;
        text-decoration: none; }
      .domofon .footer__item-chat {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #1976D2;
        text-decoration: none; }
  .domofon .carousel-root .carousel-slider {
    overflow: visible;
    padding-bottom: 40px; }
  .domofon .carousel-root .control-dots {
    bottom: 0;
    margin: 0; }
    .domofon .carousel-root .control-dots .dot {
      width: 10px;
      height: 10px;
      background: #E8F2FC;
      box-shadow: none; }
      .domofon .carousel-root .control-dots .dot.selected {
        background: #1976D2; }
  .domofon .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    visibility: hidden;
    opacity: 0;
    z-index: 100; }
    .domofon .modal.active {
      visibility: visible;
      opacity: 1; }
    .domofon .modal__content {
      position: relative;
      background-color: #fff;
      border-radius: 10px;
      padding: 40px 20px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px; }
      .domofon .modal__content-closer {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 30px;
        height: 30px;
        cursor: pointer; }
        .domofon .modal__content-closer:before, .domofon .modal__content-closer:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 2px;
          background: #1976D2;
          border-radius: 3px; }
        .domofon .modal__content-closer:before {
          transform: translateX(-50%) translateY(-50%) rotate(45deg); }
        .domofon .modal__content-closer:after {
          transform: translateX(-50%) translateY(-50%) rotate(-45deg); }

@media (max-width: 1200px) {
  .domofon .about__row {
    gap: 20px; } }

@media (max-width: 991px) {
  .domofon .header {
    margin-top: 28px; }
    .domofon .header__title h1 {
      margin-top: 23px; }
    .domofon .header__logo {
      justify-content: center; }
    .domofon .header__connect-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center; }
  .domofon .safety__list-item_big {
    max-width: 200px; }
  .domofon .about__item {
    margin: 0 auto; }
  .domofon .application__form {
    width: 100%; }
  .domofon .application__row {
    flex-direction: column;
    gap: 54px; }
  .domofon .application__img {
    flex-direction: row;
    justify-content: space-evenly; }
  .domofon .modal__content {
    border: none;
    border-radius: 0;
    width: 100%; } }

@media (max-width: 767px) {
  .domofon .application__form {
    padding: 39px 50px 49px 50px; }
  .domofon .application__img-first, .domofon .application__img-second {
    width: 159px;
    height: 120px; }
  .domofon .safety {
    margin-top: 83px;
    border-radius: 0;
    border: none;
    padding: 0 20px 0;
    gap: 36px; }
    .domofon .safety__header {
      font-size: 16px; }
    .domofon .safety__list {
      row-gap: 30px;
      column-gap: 17px; }
    .domofon .safety__list-item {
      max-width: 130px;
      gap: 17px; }
      .domofon .safety__list-item_big {
        max-width: 130px; }
    .domofon .safety__list-image {
      width: 120px;
      height: 120px; }
    .domofon .safety__list-text {
      font-size: 12px;
      line-height: 16px;
      color: #323232; } }

@media (max-width: 575px) {
  .domofon .header__title h1 {
    font-size: 22px;
    line-height: 141%;
    text-align: center; }
  .domofon .header__description {
    margin-top: 11px;
    font-weight: 400;
    font-size: 14px;
    line-height: 141%;
    text-align: center; }
  .domofon .about {
    margin-top: 31px; }
    .domofon .about__item {
      width: 100%;
      margin: 0; }
      .domofon .about__item-img {
        width: 100%;
        height: auto; }
  .domofon .application {
    margin-top: 34px; }
    .domofon .application__form {
      padding: 0;
      border: none; }
      .domofon .application__form-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 16px; }
      .domofon .application__form input, .domofon .application__form select {
        margin-bottom: 10px; }
      .domofon .application__form-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center; }
      .domofon .application__form-submit {
        width: 100%;
        height: 50px; }
    .domofon .application__note {
      margin-top: 59px; }
      .domofon .application__note-btn {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin: 0 auto; }
      .domofon .application__note-content {
        text-align: center; }
  .domofon .footer {
    margin-top: 44px; }
    .domofon .footer__row {
      flex-direction: column;
      align-items: center;
      gap: 24px; } }
