.archive-modal {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    & * {
        box-sizing: border-box;
    }

    &.hidden {
        display: none;
    }

    &__overlay {
        height: 100%;
        background-color: rgba($color: #000000, $alpha: .7);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        max-width: 970px;
        height: 352px;
        margin: 0 20px;
        padding: 55px 30px 30px;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @media (max-width: 576px) {
            height: auto;
        }
    }

    &__close-button {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 21px;
        right: 21px;
        cursor: pointer;

        @media (max-width: 576px) {
            top: 15px;
            right: 15px;
        }
        
        &::before, &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: 9px;
            background-color: #2788BC;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            content: '';
            transform: rotate(-45deg);
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__text {
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #363C4C;

        @media (max-width: 576px) {
            font-size: 20px;
            line-height: 23px;
        }
    }

    &__need-help-button {
        font-size: 16px;
        line-height: 19px;
        position: absolute;
        bottom: 21px;
        right: 20px;
        cursor: pointer;

        @media (max-width: 576px) {
            position: unset;
            margin-top: 60px;
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__button {
        padding: 0 40px;
        height: 40px;
        background: #0288D1;
        border-radius: 3px;
        color: white;
        font-size: 20px;
        line-height: 40px;
        cursor: pointer;
        border: none;
        text-decoration: none;

        @media (max-width: 576px) {
            padding: 0 30px;
        }
    }

    .blue-text {
        color: #1976D2;
        text-decoration: none;
    }
}