/*

notebook: '(max-width: 1439px)', //1023-1439
notepad: '(max-width: 1023px)', //768-1023
mobile: '(max-width: 767px)', //320-767

*/
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat.woff") format("woff");
  font-display: fallback;
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserratbold.woff") format("woff");
  font-display: fallback;
  font-weight: 700;
  font-style: normal; }

.relantPlug * {
  box-sizing: border-box; }

.relantPlug a {
  text-decoration: none; }

.relantPlug header,
.relantPlug section,
.relantPlug footer {
  width: 100%; }

.relantPlug {
  width: 770px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 0 43px 0; }
  @media (max-width: 1023px) {
    .relantPlug {
      width: 300px;
      padding: 18px 0 43px 0; } }
  .relantPlug__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    width: 850px !important; }
    @media (max-width: 1023px) {
      .relantPlug__header {
        width: 100% !important;
        gap: 18px; } }
    .relantPlug__header__logo {
      width: 481px;
      height: 119px;
      background-image: url("../images/relantPlug/logo.svg");
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 1023px) {
        .relantPlug__header__logo {
          width: 303.19px;
          height: 86.54px;
          background-size: contain; } }
    .relantPlug__header__text {
      font-weight: 700;
      font-size: 60px;
      line-height: 71px;
      text-align: center;
      color: #2788BC; }
      @media (max-width: 1023px) {
        .relantPlug__header__text {
          font-size: 36px;
          line-height: 44px; } }
  .relantPlug__info {
    margin-top: 40px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000; }
    @media (max-width: 1023px) {
      .relantPlug__info {
        margin-top: 26px;
        height: 119px;
        font-size: 14px;
        line-height: 17px; } }
  .relantPlug__card {
    margin-top: 40px;
    width: 770px;
    height: 229px;
    background: #cfe8fb;
    border-radius: 10px;
    padding: 39px 86px 50px 121px; }
    @media (max-width: 1023px) {
      .relantPlug__card {
        height: 384px;
        margin-top: 27px;
        padding: 30px 20px 34px 79px; } }
    .relantPlug__card ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%; }
    .relantPlug__card li {
      position: relative;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000; }
      @media (max-width: 1023px) {
        .relantPlug__card li {
          font-size: 14px; } }
      .relantPlug__card li:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -44px;
        content: '';
        width: 27px;
        height: 21px;
        background-image: url("../images/relantPlug/arrow.svg");
        background-position: center;
        background-repeat: no-repeat; }
  .relantPlug__link {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; }
    @media (max-width: 1023px) {
      .relantPlug__link {
        margin-top: 24px; } }
    .relantPlug__link__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #000000;
      display: flex;
      flex-direction: column; }
      @media (max-width: 1023px) {
        .relantPlug__link__text {
          font-size: 14px;
          line-height: 23px; } }
      .relantPlug__link__text a {
        font-weight: 700;
        color: #2788BC; }
    .relantPlug__link__button {
      width: 210px;
      height: 40px;
      background: #2788BC;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF; }
  .relantPlug__footer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .relantPlug__footer__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #000000; }
      @media (max-width: 1023px) {
        .relantPlug__footer__text {
          font-size: 12px;
          line-height: 16px; } }
    .relantPlug__footer__phone {
      font-weight: 700;
      font-size: 56px;
      line-height: 68px;
      color: #000000; }
      @media (max-width: 1023px) {
        .relantPlug__footer__phone {
          font-size: 27px;
          line-height: 40px; } }
