.code-page {
  width: 100%;
  margin-top: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 20px;
  min-height: 180px; }
  .code-page input {
    font-size: 20px;
    line-height: 23px;
    width: 200px;
    padding: 2px 10px;
    text-align: center;
    margin-top: 20px;
    border: 0;
    height: 40px;
    outline: 0; }
  .code-page button {
    height: 40px;
    width: 100px;
    color: white;
    background: #0288D1;
    border: 0;
    border-radius: 3px;
    font-size: 20px;
    outline: none;
    cursor: pointer;
    display: block;
    margin: 20px auto; }
    .code-page button:hover {
      background: #0078BA; }
