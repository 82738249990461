.urcameras {
  width: 100%;
  height: 400px;
  min-width: 1170px;
  background-image: url(../images/archiveBack.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 30px; }
  .urcameras.ur {
    height: 120px;
    text-align: center; }
    .urcameras.ur .ur-info {
      font-size: 16px;
      color: #363C4C;
      margin-top: 50px;
      display: inline-block; }
      .urcameras.ur .ur-info strong {
        margin-left: 10px;
        font-size: 22px;
        margin-right: 70px;
        font-weight: normal; }
      .urcameras.ur .ur-info .price {
        margin-left: 10px;
        font-size: 22px; }
        .urcameras.ur .ur-info .price.positive {
          color: #0288D1; }
        .urcameras.ur .ur-info .price.negative {
          color: #DC5273; }
      .urcameras.ur .ur-info.payed {
        margin-left: 27px;
        color: #8D94A6;
        font-size: 16px; }

.mobile-wrapper .urcameras {
  width: 350px;
  min-width: 350px;
  min-height: 330px;
  height: auto;
  padding-bottom: 20px;
  background-repeat: repeat-y; }
  .mobile-wrapper .urcameras.ur {
    width: 100%;
    height: 120px !important;
    min-height: 120px !important;
    text-align: left; }
    .mobile-wrapper .urcameras.ur .ur-info {
      display: block;
      margin-top: 10px !important;
      width: 80%;
      margin: 0 auto; }
      .mobile-wrapper .urcameras.ur .ur-info:first-child {
        margin-top: 0 !important;
        padding-top: 30px !important; }
      .mobile-wrapper .urcameras.ur .ur-info.hasDate {
        padding-top: 20px !important; }
      .mobile-wrapper .urcameras.ur .ur-info strong,
      .mobile-wrapper .urcameras.ur .ur-info .price {
        font-size: 16px; }
      .mobile-wrapper .urcameras.ur .ur-info strong {
        margin-right: 0 !important; }
      .mobile-wrapper .urcameras.ur .ur-info.payed {
        margin-top: 5px !important;
        font-size: 14px; }
  .mobile-wrapper .urcameras .input-form__wrapper {
    min-width: 350px;
    width: 350px; }
    .mobile-wrapper .urcameras .input-form__wrapper .calendar {
      max-width: 325px;
      margin: 0 auto; }
      .mobile-wrapper .urcameras .input-form__wrapper .calendar label {
        padding-top: 15px;
        margin-bottom: 15px; }
      .mobile-wrapper .urcameras .input-form__wrapper .calendar .calendar-item {
        width: 65px;
        font-size: 18px;
        margin-bottom: 20px; }
        .mobile-wrapper .urcameras .input-form__wrapper .calendar .calendar-item:nth-child(4n + 1) {
          margin-right: 0; }
    .mobile-wrapper .urcameras .input-form__wrapper .user-buttons {
      width: 100%;
      max-width: 325px;
      margin: 0 auto; }
      .mobile-wrapper .urcameras .input-form__wrapper .user-buttons label {
        padding-top: 10px;
        margin-bottom: 15px; }
      .mobile-wrapper .urcameras .input-form__wrapper .user-buttons input {
        width: 70px;
        padding-left: 10px;
        text-align: center; }
      .mobile-wrapper .urcameras .input-form__wrapper .user-buttons button {
        width: 100px;
        margin-left: 5px; }

.mobile-wrapper.ur .items-grid {
  margin-bottom: 10em; }
