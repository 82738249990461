.popup-pc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center; }
  .popup-pc .popup-pc-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000615;
    opacity: 0.7; }
  .popup-pc .popup-pc-body {
    position: relative; }
  .popup-pc__btn-close {
    position: absolute;
    z-index: 10;
    right: 40px;
    top: 40px;
    background-image: url("../images/icons/i-popup-close-black.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: white;
    padding: 8px;
    border-radius: 4px;
    border: none;
    width: 32px;
    height: 32px;
    cursor: pointer; }
  .popup-pc__btn-download {
    position: absolute;
    z-index: 10;
    width: 210px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: .3s;
    color: white;
    font-size: 30px;
    font-weight: 700;
    text-align: center; }
    .popup-pc__btn-download_1 {
      background: #ff0055;
      bottom: 126px;
      right: 76px; }
      .popup-pc__btn-download_1:hover {
        background: #e4004b; }
      @media (max-width: 1299px) {
        .popup-pc__btn-download_1 {
          bottom: 126px;
          right: 43px; } }
    .popup-pc__btn-download_2 {
      background: #ff6a00;
      bottom: 135px;
      right: 103px; }
      .popup-pc__btn-download_2:hover {
        background: #e66000; }
      @media (max-width: 1299px) {
        .popup-pc__btn-download_2 {
          bottom: 142px;
          right: 29px; } }
  .popup-pc__btn-send-request {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: .3s;
    color: white;
    font-weight: 700;
    text-align: center;
    bottom: 80px;
    left: 80px;
    width: 260px;
    height: 80px;
    font-size: 25px; }
    .popup-pc__btn-send-request_2 {
      background: #075cff; }
      .popup-pc__btn-send-request_2:hover {
        background: #044ed8; }
      @media (max-width: 1400px) {
        .popup-pc__btn-send-request_2 {
          bottom: 50px;
          left: 80px;
          height: 70px; } }
