.error-page {
  width: 100%;
  height: 355px;
  background-image: url(../images/404.png);
  background-position: center center;
  background-repeat: no-repeat; }
  .error-page a {
    font-size: 20px;
    line-height: 23px;
    width: 120px;
    text-align: center;
    color: #363C4C;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 275px;
    text-decoration: none; }
    .error-page a:hover {
      color: #757C8D; }
