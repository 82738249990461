.faq-page {
  max-width: 770px;
  margin: 0 auto 40px;
  height: auto;
  padding-bottom: 3rem; }
  .faq-page.mobile .faq-item .faq-item-title {
    background-position-x: 315px;
    padding-right: 55px; }
  .faq-page.mobile .faq-item:last-child {
    margin-bottom: 80px; }
  .faq-page .faq-item {
    margin: 10px auto;
    width: 100%; }
    .faq-page .faq-item a {
      color: #69738C;
      text-decoration: none; }
      .faq-page .faq-item a:hover {
        color: #8D94A6; }
    .faq-page .faq-item .faq-item-title {
      padding: 11px 70px 11px 26px;
      color: #363C4C;
      background-color: #E1E2E5;
      border-radius: 3px;
      font-size: 20px;
      line-height: 23px;
      background-image: url(../images/Vector.png);
      background-repeat: no-repeat;
      background-position: 735px center;
      cursor: pointer; }
      .faq-page .faq-item .faq-item-title:hover {
        color: #757C8D;
        background-color: #e1e2e580; }
      .faq-page .faq-item .faq-item-title.open {
        color: #363C4C !important;
        background-color: #e1e2e580;
        background-image: url(../images/VectorReverse.png); }
    .faq-page .faq-item .faq-item-answer {
      background-color: #e1e2e580;
      padding: 1px 70px 1px 26px;
      color: #363C4C;
      font-size: 15px; }
      .faq-page .faq-item .faq-item-answer p span.bold-text {
        font-weight: bold; }
    .faq-page .faq-item:last-child {
      margin-bottom: 3em; }
  .faq-page .faq-phone:hover {
    cursor: pointer; }
