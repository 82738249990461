@font-face {
  font-family: 'RobotoCondensed';
  src: local("RobotoCondensed"), url(../fonts/roboto_condensed/RobotoCondensed-Regular.ttf) format("truetype"); }

.content-archive_tool {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1362px;
  background-image: url(../images/archiveBack.png);
  margin-bottom: 30px;
  padding-bottom: 27px; }
  .content-archive_tool .input-form__wrapper {
    width: 1170px;
    margin: 0px auto;
    background: transparent;
    position: relative; }
  .content-archive_tool .input-range__wrapper {
    margin-top: 30px; }
  .content-archive_tool label {
    display: block;
    font-size: 20px;
    color: #363C4C; }
  .content-archive_tool .calendar label {
    padding-top: 27px;
    margin-bottom: 9px; }
  .content-archive_tool .calendar.year {
    margin-bottom: -10px;
    position: relative;
    z-index: 1;
    display: flex; }
    .content-archive_tool .calendar.year .calendar__switch {
      display: flex;
      flex-direction: column;
      padding-top: 18px; }
    .content-archive_tool .calendar.year .year-switch-hidden {
      position: absolute;
      background: white;
      width: 95px;
      text-align: left;
      top: 58px;
      margin-top: 1px; }
      .content-archive_tool .calendar.year .year-switch-hidden .year-switch_item {
        cursor: pointer;
        color: #69738C;
        padding: 5px 10px 5px 15px;
        cursor: pointer; }
        .content-archive_tool .calendar.year .year-switch-hidden .year-switch_item:hover {
          background: #F5F5F5;
          color: #363C4C; }
    .content-archive_tool .calendar.year label {
      display: inline-block;
      margin-right: 20px; }
  .content-archive_tool .timeline label {
    padding-top: 24px;
    margin-bottom: 6px; }
  .content-archive_tool .user-buttons .begin-wrapper,
  .content-archive_tool .user-buttons .end-wrapper {
    display: inline-block; }
    .content-archive_tool .user-buttons .begin-wrapper .pseudo-input,
    .content-archive_tool .user-buttons .end-wrapper .pseudo-input {
      background: white;
      padding: 0 10px;
      border-radius: 3px;
      width: 110px;
      margin-right: 5px;
      background: #EDEEF0; }
      .content-archive_tool .user-buttons .begin-wrapper .pseudo-input.screen,
      .content-archive_tool .user-buttons .end-wrapper .pseudo-input.screen {
        width: 60px;
        margin-right: 20px;
        padding-left: 0 !important; }
  .content-archive_tool .user-buttons label {
    padding-top: 60px;
    margin-bottom: 19px; }
  .content-archive_tool .user-buttons.screen label {
    padding-top: 45px !important; }
  .content-archive_tool .user-buttons input {
    outline: 0 !important;
    width: 60px;
    height: 40px;
    border-radius: 3px;
    font-size: 20px;
    line-height: 40px;
    color: #363C4C;
    margin-right: 20px;
    border: 0;
    text-align: center;
    padding: 0px 10px; }
    .content-archive_tool .user-buttons input.screen {
      margin-right: 5px;
      padding-left: 10px; }
  .content-archive_tool .user-buttons button {
    font-size: 20px;
    color: white;
    width: 180px;
    height: 40px;
    line-height: 40px;
    margin-left: 30px;
    background: #0288D1;
    border-radius: 3px;
    border: 0;
    cursor: pointer; }
    .content-archive_tool .user-buttons button:hover {
      background: #0078BA; }

.share-wrapper {
  position: absolute;
  width: 710px;
  background: white;
  height: 393px;
  top: 0px;
  padding: 20px 30px;
  /*    opacity: 0;
      animation-name: example;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; */ }
  .share-wrapper.sosed {
    position: absolute;
    height: 185px;
    z-index: 3;
    font-size: 16px;
    line-height: 19px;
    color: #363C4C;
    top: initial;
    margin-left: -1px;
    margin-top: -285px;
    -webkit-box-shadow: 2px 2px 5px 0px #edeef0;
    -moz-box-shadow: 2px 2px 5px 0px #edeef0;
    box-shadow: 2px 2px 5px 0px #edeef0; }
  .share-wrapper.sosed-archive {
    width: 800px;
    height: 425px;
    border-radius: 3px;
    padding: 35px 50px 40px 50px;
    z-index: 4;
    top: 50%;
    margin-top: -232px;
    left: 50%;
    margin-left: -450px;
    position: fixed; }
    .share-wrapper.sosed-archive .sended {
      text-align: center;
      font-size: 20px; }
      .share-wrapper.sosed-archive .sended h3 {
        margin-top: 50px;
        text-align: center; }
      .share-wrapper.sosed-archive .sended .step-text.name {
        width: 100% !important; }
    .share-wrapper.sosed-archive .search-result {
      position: absolute;
      background: white;
      width: 150px;
      top: 50px;
      z-index: 3;
      border: 1px solid #DEE0E3; }
      .share-wrapper.sosed-archive .search-result ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .share-wrapper.sosed-archive .search-result ul li {
          height: 30px;
          color: #363C4C;
          cursor: pointer;
          padding: 1px 0px 0 10px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .share-wrapper.sosed-archive .search-result ul li:hover {
            color: #69738C; }
    .share-wrapper.sosed-archive .error {
      border-color: red !important;
      text-align: left !important; }
    .share-wrapper.sosed-archive .error-sosed {
      text-align: center;
      display: block;
      color: red;
      margin-top: 20px;
      width: 100%; }
    .share-wrapper.sosed-archive .red-text {
      color: red; }
    .share-wrapper.sosed-archive .sosed-wizzard {
      width: 500px;
      margin: 20px auto; }
      .share-wrapper.sosed-archive .sosed-wizzard_bar {
        height: 4px;
        background: #DEE0E3;
        width: 500px;
        position: absolute;
        margin-top: 20px; }
      .share-wrapper.sosed-archive .sosed-wizzard_step {
        position: absolute;
        width: 33%;
        height: 4px; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.step-1 {
          left: 0; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.step-2 {
          left: 33%; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.step-3 {
          left: 66%;
          width: 34%; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.step-4 {
          right: 0;
          width: 0; }
        .share-wrapper.sosed-archive .sosed-wizzard_step span {
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          background: #DEE0E3;
          top: -3px;
          border-radius: 50%;
          cursor: pointer; }
        .share-wrapper.sosed-archive .sosed-wizzard_step label {
          font-size: 16px;
          line-height: 19px;
          color: #C2C8D8;
          padding: 2px 10px;
          width: 65px;
          text-align: center;
          background: transparent;
          border-radius: 3px;
          position: absolute;
          top: -40px;
          margin-left: -35px;
          cursor: pointer; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.filled {
          background-color: #0288D1; }
          .share-wrapper.sosed-archive .sosed-wizzard_step.filled label {
            color: #0288D1; }
          .share-wrapper.sosed-archive .sosed-wizzard_step.filled span {
            background-color: #0288D1; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.active label {
          color: white;
          background: #0288D1; }
          .share-wrapper.sosed-archive .sosed-wizzard_step.active label:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: #0288D1 transparent transparent transparent;
            position: absolute;
            left: 35px;
            top: 23px; }
        .share-wrapper.sosed-archive .sosed-wizzard_step.active span {
          width: 16px;
          height: 16px;
          background: #0288D1;
          top: -6px; }
    .share-wrapper.sosed-archive h3 {
      margin-top: 60px;
      margin-bottom: 0px;
      color: #363C4C;
      text-align: left; }
    .share-wrapper.sosed-archive .step-text {
      width: 350px; }
      .share-wrapper.sosed-archive .step-text.name {
        width: 650px; }
        .share-wrapper.sosed-archive .step-text.name a {
          text-decoration: none;
          color: #0288D1;
          font-size: 20px; }
          .share-wrapper.sosed-archive .step-text.name a:hover {
            color: #0078BA; }
      .share-wrapper.sosed-archive .step-text.streets {
        display: inline-block; }
      .share-wrapper.sosed-archive .step-text.unfinish {
        width: 80%;
        font-size: 24px;
        text-align: center;
        margin: 50px auto; }
      .share-wrapper.sosed-archive .step-text label {
        display: inline-block;
        width: 73px;
        font-size: 14px;
        line-height: 40px;
        margin-right: 11px; }
      .share-wrapper.sosed-archive .step-text textarea,
      .share-wrapper.sosed-archive .step-text input {
        display: inline-block;
        height: 40px;
        border: 1px solid #DEE0E3;
        margin: 0;
        padding-left: 10px;
        font-size: 20px;
        width: 300px;
        outline: 0; }
      .share-wrapper.sosed-archive .step-text textarea {
        height: 80px;
        resize: none; }
    .share-wrapper.sosed-archive .info-text {
      display: inline-block;
      font-size: 14px; }
    .share-wrapper.sosed-archive .step-buttons {
      width: 260px;
      text-align: center;
      position: absolute;
      bottom: 35px;
      left: 50%;
      margin-left: -130px; }
      .share-wrapper.sosed-archive .step-buttons button {
        font-size: 18px;
        color: white;
        width: 120px;
        height: 40px;
        line-height: 40px;
        background: #0288D1;
        border-radius: 3px;
        border: 0;
        cursor: pointer;
        outline: 0; }
        .share-wrapper.sosed-archive .step-buttons button.prev {
          margin-right: 17px; }
        .share-wrapper.sosed-archive .step-buttons button.disabled {
          background: #DEE0E3 !important;
          color: #69738C;
          cursor: initial;
          margin-right: 17px; }
        .share-wrapper.sosed-archive .step-buttons button.next.disabled {
          margin-right: 0; }
        .share-wrapper.sosed-archive .step-buttons button:hover {
          background: #0078BA; }
    .share-wrapper.sosed-archive .step-scroll {
      right: 0;
      top: 0;
      display: inline-block;
      margin-left: 50px;
      vertical-align: top;
      position: relative; }
      .share-wrapper.sosed-archive .step-scroll input {
        display: inline-block;
        height: 40px;
        border: 1px solid #DEE0E3;
        margin: 0;
        padding-left: 10px;
        font-size: 20px;
        outline: 0; }
      .share-wrapper.sosed-archive .step-scroll #sosedStreet {
        width: 140px; }
      .share-wrapper.sosed-archive .step-scroll #sosedHome {
        width: 60px;
        margin-left: 10px; }
      .share-wrapper.sosed-archive .step-scroll button {
        font-size: 18px;
        color: white;
        width: 110px;
        height: 40px;
        line-height: 40px;
        background: #0288D1;
        border-radius: 3px;
        border: 0;
        cursor: pointer;
        outline: 0;
        margin-left: 20px; }
        .share-wrapper.sosed-archive .step-scroll button:hover {
          background: #0078BA; }
      .share-wrapper.sosed-archive .step-scroll #rules {
        display: inline-block;
        margin-top: 20px;
        cursor: pointer; }
        .share-wrapper.sosed-archive .step-scroll #rules input {
          vertical-align: middle;
          width: 20px;
          height: 20px; }
        .share-wrapper.sosed-archive .step-scroll #rules span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 15px; }
      .share-wrapper.sosed-archive .step-scroll .scroll-list {
        width: 360px;
        height: 100px;
        border: 1px solid #DEE0E3;
        padding: 0;
        margin-top: 20px; }
        .share-wrapper.sosed-archive .step-scroll .scroll-list.rules {
          height: 150px; }
          .share-wrapper.sosed-archive .step-scroll .scroll-list.rules p {
            padding: 0 10px; }
        .share-wrapper.sosed-archive .step-scroll .scroll-list ul {
          margin: 0;
          padding: 0; }
          .share-wrapper.sosed-archive .step-scroll .scroll-list ul li {
            padding: 3px 15px;
            margin: 0;
            list-style: none;
            position: relative; }
            .share-wrapper.sosed-archive .step-scroll .scroll-list ul li .delete {
              display: block;
              position: absolute;
              top: 3px;
              right: 15px;
              width: 15px;
              height: 15px;
              background: url(../images/garbage.png);
              background-repeat: no-repeat;
              background-position: center center;
              cursor: pointer; }
              .share-wrapper.sosed-archive .step-scroll .scroll-list ul li .delete:hover {
                opacity: 0.85; }
    .share-wrapper.sosed-archive .share-wrapper_preview {
      top: 140px;
      right: 50px; }
  .share-wrapper_close {
    display: block;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: absolute;
    background-image: url(../images/close.png);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    top: 17px;
    right: 20px; }
  .share-wrapper_header {
    font-size: 24px;
    line-height: 28px;
    color: #363C4C; }
  .share-wrapper_text {
    font-size: 16px;
    line-height: 19px;
    color: #363C4C;
    width: 230px; }
  .share-wrapper_preview {
    width: 430px;
    height: 242px;
    position: absolute;
    top: 65px;
    right: 30px; }
    .share-wrapper_preview .camera-dimensions {
      width: 430px !important;
      height: 242px !important; }
  .share-wrapper_buttons {
    position: absolute;
    bottom: 27px; }
    .share-wrapper_buttons .copy-success {
      position: absolute;
      left: 10px;
      bottom: 59px;
      color: #0288D1; }
    .share-wrapper_buttons input {
      outline: 0 !important;
      width: 446px;
      height: 40px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      font-size: 20px;
      line-height: 40px;
      color: #363C4C;
      margin-right: 10px;
      padding-left: 10px;
      border: 1px solid #DEE0E3; }
      .share-wrapper_buttons input.error-share {
        border-color: red; }
    .share-wrapper_buttons button {
      font-size: 20px;
      color: white;
      width: 210px;
      height: 40px;
      line-height: 40px;
      margin-left: 30px;
      background: #0288D1;
      border-radius: 3px;
      border: 0;
      cursor: pointer; }
      .share-wrapper_buttons button:hover {
        background: #0078BA; }

@keyframes example {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.calendar .calendar-item {
  display: inline-block;
  margin-right: 20px;
  width: 70px;
  height: 45px;
  background: #3B5077;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  line-height: 19px;
  padding-top: 10px; }
  .calendar .calendar-item.screen-item {
    padding: 10px 8px 0px 8px !important;
    margin-right: 10px !important;
    width: auto !important;
    height: 32px; }
  .calendar .calendar-item span {
    display: block; }
  .calendar .calendar-item.current {
    background: #0288D1; }

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #0288D1;
  border: 1px solid #0288D1;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
  margin-left: -8px;
  margin-top: -12px;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  .input-range__slider :focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-size: 0.8rem;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 8px;
  position: relative;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out; }

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  -webkit-transform: none;
  transform: none; }

.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 15px; }

.input-range__label--max {
  right: 15px; }

.input-range__label--value {
  position: absolute; }
  .input-range__label--value .input-range__label-container {
    background: #0288D1;
    color: white;
    padding: 3px 10px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 19px; }

.input-range__slider-container:nth-child(2) .input-range__label--value {
  top: 20px; }
  .input-range__slider-container:nth-child(2) .input-range__label--value:before {
    content: '';
    position: absolute;
    left: -8px;
    bottom: 21px;
    border: 8px solid transparent;
    border-bottom: 8px solid #0288D1; }

.input-range__slider-container:nth-child(3) .input-range__label--value {
  top: -44px; }
  .input-range__slider-container:nth-child(3) .input-range__label--value:before {
    content: '';
    position: absolute;
    left: -8px;
    bottom: -16px;
    border: 8px solid transparent;
    border-top: 8px solid #0288D1; }

.input-range__label-container {
  left: -50%;
  position: relative;
  color: #69738C; }

.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #0288D1; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

/*# sourceMappingURL=react-input-range.css.map*/
.input-range__label input-range__label--value,
.input-range__label-container {
  pointer-events: none; }

.input-range__track--background {
  background: url(../images/timeline.png);
  background-repeat: no-repeat;
  background-position: center center; }

.year-switch {
  display: inline-block;
  background: white;
  width: 80px;
  height: 40px;
  font-size: 20px;
  background-image: url(../images/Vector.png);
  background-repeat: no-repeat;
  background-position: 70px center;
  line-height: 38px;
  padding-left: 15px;
  border-radius: 3px;
  cursor: pointer; }

.screen-content-block .screen-value {
  margin-top: 1px;
  margin-right: 20px;
  display: inline-block; }

.screen-content-block .screen-description {
  display: inline-block;
  position: absolute;
  width: 350px;
  height: 240px;
  font-size: 15px; }
  .screen-content-block .screen-description .screen-description-padding {
    margin-bottom: 1em; }
  .screen-content-block .screen-description .screen-description-double-padding {
    margin-bottom: 2em; }
  .screen-content-block .screen-description .main-item {
    margin-bottom: 0.5em;
    text-align: left; }
    .screen-content-block .screen-description .main-item .circle-number-wrapper {
      display: inline-block;
      background: #0288D1;
      color: white;
      text-align: center;
      border-radius: 50%;
      margin-right: 5px;
      height: 1.2em;
      width: 1.2em; }
      .screen-content-block .screen-description .main-item .circle-number-wrapper .circle-number {
        font-size: 14px;
        display: initial; }

.mobile-wrapper .content-archive_tool {
  width: 350px;
  min-width: 350px;
  min-height: 330px;
  height: auto;
  padding-bottom: 20px;
  background-repeat: repeat-y; }
  .mobile-wrapper .content-archive_tool .input-form__wrapper {
    min-width: 350px;
    width: 350px; }
    .mobile-wrapper .content-archive_tool .input-form__wrapper .calendar {
      max-width: 325px;
      margin: 0 auto; }
      .mobile-wrapper .content-archive_tool .input-form__wrapper .calendar label {
        padding-top: 15px;
        margin-bottom: 15px; }
      .mobile-wrapper .content-archive_tool .input-form__wrapper .calendar .calendar-item {
        width: 65px;
        font-size: 18px;
        margin-bottom: 20px; }
        .mobile-wrapper .content-archive_tool .input-form__wrapper .calendar .calendar-item:nth-child(4n + 1) {
          margin-right: 0; }
    .mobile-wrapper .content-archive_tool .input-form__wrapper .user-buttons {
      width: 100%;
      max-width: 325px;
      margin: 0 auto; }
      .mobile-wrapper .content-archive_tool .input-form__wrapper .user-buttons label {
        padding-top: 10px;
        margin-bottom: 15px; }
      .mobile-wrapper .content-archive_tool .input-form__wrapper .user-buttons input {
        width: 80px;
        text-align: center;
        margin-right: 10px;
        font-size: 16px; }
      .mobile-wrapper .content-archive_tool .input-form__wrapper .user-buttons button {
        width: 80px;
        margin-left: 5px;
        font-size: 16px; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-webkit-search-results-button {
  -webkit-appearance: none; }

input[type=search] {
  -webkit-appearance: none; }

.sosed-archive__wrapper {
  width: 270px;
  z-index: 3;
  display: inline-block;
  margin-left: 75px; }
  .sosed-archive__wrapper .sosed-back {
    width: 270px;
    height: 364px;
    background-image: url(../images/sosedBack.png);
    background-repeat: no-repeat;
    background-position: center center; }
  .sosed-archive__wrapper .sosed-description {
    padding: 10px 25px;
    font-size: 15px;
    color: white; }
  .sosed-archive__wrapper .sosed-question {
    color: #69738C;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 13px auto;
    cursor: pointer; }
    .sosed-archive__wrapper .sosed-question:hover {
      color: #8D94A6; }
  .sosed-archive__wrapper .sosed-answer {
    background: #E4E5E8;
    padding: 25px;
    font-size: 15px;
    top: 0px;
    position: absolute;
    width: 220px; }
    .sosed-archive__wrapper .sosed-answer a {
      text-decoration: none;
      color: #0288D1; }
      .sosed-archive__wrapper .sosed-answer a:hover {
        color: #0078BA; }
    .sosed-archive__wrapper .sosed-answer_close {
      display: block;
      width: 13px;
      height: 13px;
      cursor: pointer;
      position: absolute;
      background-image: url(../images/close.png);
      background-position: 0px 0px;
      background-repeat: no-repeat;
      background-size: contain;
      top: 13px;
      right: 16px; }
  .sosed-archive__wrapper button {
    font-size: 18px;
    color: white;
    width: 220px;
    height: 40px;
    line-height: 40px;
    margin-left: 30px;
    background: #0288D1;
    border-radius: 3px;
    border: 0;
    cursor: pointer; }
    .sosed-archive__wrapper button:hover {
      background: #0078BA; }
