.footer-wrapper {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 auto; }

.footer-wrapper-mobile {
  width: 100%;
  height: unset;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 auto; }

.App-footer {
  flex: 0 0 auto;
  width: 100%;
  min-width: 1180px;
  background: #E4E5E8;
  height: 80px; }
  .App-footer__wrapper {
    display: flex;
    justify-content: space-around;
    height: 40px;
    padding-top: 20px;
    line-height: 40px; }
    .App-footer__wrapper .footer-link {
      display: block;
      width: 80%;
      height: 100%;
      padding-bottom: 0px; }
    .App-footer__wrapper a {
      font-size: 20px;
      padding: 10px 50px 10px 0px;
      color: #69738C;
      text-decoration: none; }
      .App-footer__wrapper a:hover {
        color: #8D94A6; }
      .App-footer__wrapper a.copyright {
        cursor: default !important; }
        .App-footer__wrapper a.copyright:hover {
          color: #69738C; }
      .App-footer__wrapper a.play-google {
        padding-right: 152px;
        background-image: url(../images/googlePlay.png);
        background-position: center right;
        background-repeat: no-repeat; }

.sticky .mobile-footer {
  margin-top: -180px; }

.mobile-footer {
  width: 100%;
  min-width: 350px;
  background: #E4E5E8;
  height: 150px;
  margin: -15px auto 0; }
  .mobile-footer__wrapper {
    width: 100%;
    min-width: 350px;
    margin: 0 auto;
    position: relative;
    padding-top: 15px;
    line-height: 30px; }
    .mobile-footer__wrapper a {
      display: block;
      text-align: center;
      font-size: 20px;
      padding: 0;
      color: #69738C;
      text-decoration: none; }
      .mobile-footer__wrapper a.pc-version {
        margin-bottom: 25px; }
      .mobile-footer__wrapper a:hover {
        color: #8D94A6; }
