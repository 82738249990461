.custom_select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #363C4C;
  font-size: 18px;
  border-bottom: 2px #0288D1 solid;
  padding-left: 4px;
  padding-right: 4px; }
  .custom_select_mobile {
    width: 100%;
    justify-content: space-between; }
  .custom_select__select {
    width: 140px;
    height: 40px;
    font-size: 16px;
    background: none !important;
    color: #363C4C;
    border: none; }
    .custom_select__select:focus-visible {
      outline: none !important; }
    .custom_select__select option {
      color: #363C4C; }
