@font-face {
  font-family: 'RobotoCondensed';
  src: local("RobotoCondensed"), url(../fonts/roboto_condensed/RobotoCondensed-Regular.ttf) format("truetype"); }

.public-camera-right-block {
  width: 400px;
  height: 433px;
  display: block;
  position: absolute;
  right: 0; }
  .public-camera-right-block div.public-camera-right-block-image-wrapper {
    height: 100%;
    width: 100%; }
    .public-camera-right-block div.public-camera-right-block-image-wrapper .public-camera-right-block-image {
      background-repeat: no-repeat;
      background-position: center center;
      height: 100%;
      width: 100%; }
    .public-camera-right-block div.public-camera-right-block-image-wrapper .link-button {
      background-color: #FFF247;
      bottom: 1em;
      right: 3em;
      border-radius: 3px;
      border-color: #FFF247;
      border-style: solid;
      position: absolute;
      width: 285px;
      height: 40px;
      font-family: RobotoCondensed;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-align: center;
      color: #363C4C; }

.mobile-wrapper .public-camera-promo-block {
  margin-top: 2rem;
  width: 400px;
  height: 433px;
  display: block; }
  .mobile-wrapper .public-camera-promo-block .public-camera-promo-block-link-wrapper .public-camera-promo-block-link {
    display: block;
    position: relative; }
    .mobile-wrapper .public-camera-promo-block .public-camera-promo-block-link-wrapper .public-camera-promo-block-link .link-button {
      background-color: #FFF247;
      top: 1.7em;
      left: 1.5em;
      border-radius: 3px;
      border-color: #FFF247;
      border-style: solid;
      position: absolute;
      width: 130px;
      height: 50px;
      font-family: RobotoCondensed;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      color: #363C4C; }
