.archive-error-page {
  width: 100%;
  height: 355px;
  background-image: url(../images/archiveBack.png);
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;

  h3 {
    line-height: 30px;
    width: 60%;
    color: #363C4C;
    position: absolute;
    text-decoration: none;
    margin-left: 20%;
    top: 275px;
  }
}
