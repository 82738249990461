@font-face {
  font-family: 'RobotoCondensed';
  src: local("RobotoCondensed"), url(../fonts/roboto_condensed/RobotoCondensed-Regular.ttf) format("truetype"); }

.share-btns-enter {
  opacity: 0.01; }

.share-btns-enter.share-btns-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.share-btns-leave {
  opacity: 1; }

.share-btns-leave.share-btns-leave-active {
  opacity: 0.01;
  transition: opacity .5s ease-in-out; }

.share-btns-appear {
  opacity: 0.01; }

.share-btns-appear.share-btns-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.public-wrapper {
  padding: 0 5px;
  margin-bottom: 100px; }

.content-navigate {
  width: 1120px;
  margin-top: 18px;
  position: absolute;
  left: 50%;
  margin-left: -560px;
  z-index: 3;
  color: #363C4C;
  font-size: 20px;
  line-height: 50px; }
  .content-navigate.publist {
    width: 1120px;
    margin: 0 auto 37px;
    padding: 18px 30px 18px 25px;
    position: relative;
    left: initial;
    background: #E3E4E6; }
    .content-navigate.publist .content-navigate__switch {
      float: right; }
  .content-navigate__city {
    display: inline-block;
    background: white;
    height: 50px;
    width: 175px;
    color: #363C4C;
    text-align: left;
    padding-left: 15px;
    margin-right: 1px;
    cursor: pointer;
    background-image: url(../images/Vector.png);
    background-repeat: no-repeat;
    background-position: 166px 22px; }
    .content-navigate__city:hover {
      color: #69738C; }
  .content-navigate__hiddenList {
    position: absolute;
    width: 190px;
    background: white;
    margin-top: 1px; }
    .content-navigate__hiddenList ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .content-navigate__hiddenList ul li {
        font-size: 20px;
        cursor: pointer;
        line-height: 39px;
        text-align: center;
        color: #69738C; }
        .content-navigate__hiddenList ul li:hover {
          background: #F5F5F5;
          color: #363C4C; }
  .content-navigate__search {
    display: inline-block;
    background: white;
    height: 50px;
    width: 640px;
    position: relative; }
    .content-navigate__search input {
      height: 40px;
      border: 0;
      margin: 0;
      padding-left: 60px;
      font-size: 20px;
      background-image: url(../images/search.png);
      background-position: 22px 10px;
      background-repeat: no-repeat;
      width: 480px;
      outline: 0; }
      .content-navigate__search input::placeholder {
        color: #69738C; }
    .content-navigate__search .search {
      height: 50px;
      width: 100px;
      color: white;
      background: #0288D1;
      border: 0;
      position: absolute;
      right: 0;
      font-size: 20px;
      outline: none;
      cursor: pointer; }
      .content-navigate__search .search:hover {
        background: #0078BA; }
    .content-navigate__search .search-result {
      position: absolute;
      background: white;
      width: 540px;
      top: 51px; }
      .content-navigate__search .search-result ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .content-navigate__search .search-result ul li {
          height: 40px;
          color: #363C4C;
          cursor: pointer; }
          .content-navigate__search .search-result ul li a {
            display: block;
            width: 100%;
            padding-left: 20px; }
          .content-navigate__search .search-result ul li:hover {
            color: #69738C; }
          .content-navigate__search .search-result ul li:last-child {
            margin-bottom: 20px; }
  .content-navigate__switch {
    display: inline-block;
    height: 50px;
    width: 220px;
    float: right; }
    .content-navigate__switch a {
      text-decoration: none;
      color: #949BAC;
      background: white;
      width: 108px;
      display: inline-block;
      margin: 0 0 0 1px;
      padding: 0;
      text-align: center; }
      .content-navigate__switch a.active, .content-navigate__switch a:hover {
        color: #363C4C; }
  .content-navigate__tools {
    width: 50px;
    position: absolute;
    right: 30px;
    top: 155px;
    z-index: 5; }
    .content-navigate__tools .tool {
      width: 50px;
      height: 50px;
      background: white;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 0.85; }
      .content-navigate__tools .tool:hover {
        opacity: 1; }
    .content-navigate__tools #zoom-in {
      background-image: url(../images/mapZoomIn.png);
      margin-bottom: 1px; }
    .content-navigate__tools #zoom-out {
      background-image: url(../images/mapZoomOut.png);
      margin-bottom: 30px; }
    .content-navigate__tools #zoom-rakurs {
      display: none;
      background-image: url(../images/mapRakurses.png); }

.mobile-navigate {
  position: relative;
  min-width: 350px;
  width: 100%;
  margin: 0 auto; }
  .mobile-navigate__wrap {
    background-image: url(../images/Vector.png);
    background-repeat: no-repeat;
    background-position: 30px 18px; }
  .mobile-navigate__city {
    display: block;
    height: 40px;
    width: 190px;
    color: #363C4C;
    text-align: left;
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
    margin: 29px 0 30px 69px; }
  .mobile-navigate__search {
    display: block;
    height: 40px;
    width: 350px;
    margin: 0 auto 15px; }
    .mobile-navigate__search input {
      height: 40px;
      border: 0;
      margin: 0 auto;
      padding-left: 60px;
      font-size: 20px;
      background-image: url(../images/search.png);
      background-position: 22px 10px;
      background-repeat: no-repeat;
      width: 290px;
      outline: 0; }
      .mobile-navigate__search input::placeholder {
        color: #69738C; }
  .mobile-navigate__hiddenList {
    position: absolute;
    width: 190px;
    background: white;
    margin-top: 1px;
    left: 50%;
    margin-left: -165px;
    top: 45px;
    z-index: 1; }
    .mobile-navigate__hiddenList ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .mobile-navigate__hiddenList ul li {
        font-size: 20px;
        cursor: pointer;
        line-height: 39px;
        text-align: center;
        color: #69738C; }
        .mobile-navigate__hiddenList ul li:hover {
          background: #F5F5F5;
          color: #363C4C; }

.yandex-map {
  margin: 0 auto 1px;
  text-align: center;
  width: 100%;
  height: 800px; }

.ymaps-2-1-79-map {
  z-index: 2 !important; }

.player-balloon {
  width: 510px;
  min-height: 475px;
  position: absolute;
  left: 0;
  top: 80px;
  background: white;
  z-index: 3;
  transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out; }
  .player-balloon.citylink_client {
    min-height: 250px !important; }
  .player-balloon .balloon-head {
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    padding: 15px 0 5px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    color: #363C4C;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .player-balloon .balloon-head a {
      color: #363C4C;
      text-decoration: none; }
  .player-balloon .share_buttons {
    margin-left: 15px; }
    .player-balloon .share_buttons div {
      display: inline-block;
      margin-right: 5px; }
    .player-balloon .share_buttons .copy-block {
      margin: 0;
      position: absolute;
      line-height: 20px; }
      .player-balloon .share_buttons .copy-block input {
        height: 1.64rem;
        margin-right: 5px; }
      .player-balloon .share_buttons .copy-block button.copt-btn {
        height: 2.0rem;
        color: white;
        background: #0387d1;
        border: 0;
        font-size: 14px;
        outline: none;
        cursor: pointer; }
  .player-balloon .balloon-links {
    text-align: left;
    margin: 15px 0 0 20px;
    font-size: 17px;
    color: #69738C;
    padding-bottom: 10px;
    line-height: 20px !important;
    position: relative; }
    .player-balloon .balloon-links #point_rakurs {
      margin-right: 5px; }
    .player-balloon .balloon-links .links {
      vertical-align: top;
      display: inline-block; }
    .player-balloon .balloon-links .share_ico {
      margin: 0;
      cursor: pointer;
      background-image: url(../images/share_icon.svg);
      background-position: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
      width: 30px;
      height: 20px;
      padding: 0;
      position: absolute;
      top: 0;
      right: 15px; }
    .player-balloon .balloon-links a, .player-balloon .balloon-links span {
      cursor: pointer;
      margin-right: 20px;
      text-decoration: none;
      color: #69738C; }
      .player-balloon .balloon-links a:hover, .player-balloon .balloon-links span:hover {
        color: #363C4C; }
  .player-balloon.share-config .share_ico {
    bottom: 41px; }
  .player-balloon .balloon-player {
    margin-left: 20px;
    margin-top: 5px; }
  .player-balloon #balloon_close {
    display: block;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: absolute;
    background-image: url(../images/close.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    top: 17px;
    right: 20px; }
  .player-balloon .public-camera-promo-block {
    width: 510px;
    height: 100px;
    display: block;
    position: absolute;
    right: 0; }
    .player-balloon .public-camera-promo-block .public-camera-promo-block-image-wrapper {
      height: 100%;
      width: 100%; }
      .player-balloon .public-camera-promo-block .public-camera-promo-block-image-wrapper .public-camera-promo-block-image {
        background-repeat: no-repeat;
        background-position: center center;
        height: 100%;
        width: 100%; }
      .player-balloon .public-camera-promo-block .public-camera-promo-block-image-wrapper .link-button {
        background-color: #FFF247;
        top: 1.6em;
        left: 3.5em;
        border-radius: 3px;
        border-color: #FFF247;
        border-style: solid;
        position: absolute;
        width: 130px;
        height: 50px;
        font-family: RobotoCondensed;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #363C4C; }

.player-balloon-with-promo {
  min-height: 520px !important; }

.ymaps-2-1-79-balloon-overlay .ymaps-2-1-79-image-with-content {
  background-image: none !important; }

#balloon_close {
  font-size: 24px;
  cursor: pointer; }

.ymaps-2-1-79-placemark-overlay .ymaps-2-1-79-image-with-content-content ymaps {
  display: none !important; }

.ymaps-2-1-79-default-cluster {
  color: white;
  font-weight: bold; }

.ymaps-2-1-79-placemark-overlay .ymaps-2-1-79-image-with-content {
  z-index: 2; }

.rakurs {
  background-image: url(../images/rakurs.png);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  transform: rotate(108deg);
  opacity: 0.85;
  margin-left: -3px;
  margin-top: -35px;
  width: 100px;
  height: 100px;
  z-index: 1; }
  .rakurs.active {
    background-image: url(../images/rakursActive.png); }

.content-navigate__tools.sensorFix {
  top: 0px !important; }

.player-balloon.sensorFix {
  width: 300px;
  height: 300px;
  min-height: 300px !important; }

.plot-container .plotly {
  margin: 20px auto !important; }

.content-navigate__switch .sensorFix {
  width: 217px;
  margin-top: 20px; }
  .content-navigate__switch .sensorFix sup {
    color: #0288D1;
    margin: 0 20px 0 -20px;
    font-size: 15px;
    font-weight: bold; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
