.search-bar {
  display: flex;
  width: calc(100% - 16px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-bottom: 12px; }
  .search-bar_mobile {
    flex-direction: column; }
