@charset "UTF-8";
.get-code-transition-wrapper-enter {
  opacity: 0.01; }

.get-code-transition-wrapper-enter.get-code-transition-wrapper-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.get-code-transition-wrapper-leave {
  opacity: 1; }

.get-code-transition-wrapper-leave.get-code-transition-wrapper-leave-active {
  opacity: 0.01;
  transition: opacity .5s ease-in-out; }

.get-code-transition-wrapper-appear {
  opacity: 0.01; }

.get-code-transition-wrapper-appear.get-code-transition-wrapper-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.get-code-block .get-code-btn {
  margin-bottom: 40222px;
  color: #0288D1;
  cursor: pointer; }

.get-code-block .get-code-wrapper {
  min-height: max-content; }
  .get-code-block .get-code-wrapper .get-code-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px; }
  .get-code-block .get-code-wrapper .get-code-content {
    display: block;
    width: 800px;
    margin: 0 auto;
    min-height: 600px; }
    .get-code-block .get-code-wrapper .get-code-content .get-code-instruction {
      display: inline-block;
      width: 380px;
      margin: 20px 20px 20px 0; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items {
        margin: 0;
        font-size: 16px;
        text-align: left; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .circle-number-wrapper {
          display: inline-block;
          background: #0288D1;
          color: white;
          text-align: center;
          border-radius: 50%;
          margin-right: 5px;
          height: 1em;
          width: 1em; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .circle-number-wrapper .circle-number {
            font-size: 14px; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .main-item {
          margin-bottom: 15px; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .main-item .sub-items {
            list-style: none;
            padding: 0;
            padding-left: 25px; }
            .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .main-item .sub-items:first-child {
              margin-top: 10px; }
            .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .main-item .sub-items .sub-item {
              display: list-item;
              padding: 0;
              margin: 0;
              margin-bottom: 10px; }
              .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .main-items .main-item .sub-items .sub-item:before {
                content: "—";
                position: relative;
                left: -5px; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .wrapper-info {
        margin: 30px 0 0 0;
        font-size: 16px;
        text-align: left; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .wrapper-info .header-info {
          font-weight: bold; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .wrapper-info .sub-items {
          list-style: none;
          padding: 0;
          margin: 10px 0 0 5px; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .wrapper-info .sub-items .sub-item {
            display: list-item;
            padding: 0;
            margin: 0;
            margin-bottom: 10px; }
            .get-code-block .get-code-wrapper .get-code-content .get-code-instruction .wrapper-info .sub-items .sub-item:before {
              content: "—";
              position: relative;
              left: -5px; }
    .get-code-block .get-code-wrapper .get-code-content .get-code-form {
      display: inline-block;
      width: 320px;
      min-height: 650px;
      font-size: 14px;
      vertical-align: top;
      margin: 20px; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form .get-code-error {
        border: 2px solid #ff0000; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper {
        text-align: left; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .city {
          width: 267px;
          font-size: 15px;
          cursor: pointer; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .address {
          margin-bottom: 10px;
          margin-top: 10px; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .address .address-item {
            display: inline-block;
            margin-right: 10px; }
            .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .address .address-item select {
              font-size: 15px;
              cursor: pointer; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .address .home-item {
            margin-right: 20px; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .address .delete-item {
            cursor: pointer;
            display: inline-block;
            width: 25px;
            height: 25px;
            position: relative;
            vertical-align: middle;
            background-image: url(../images/close-blue.svg);
            background-position: 0 0;
            background-repeat: no-repeat; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .address-add {
          cursor: pointer;
          color: #0288D1;
          text-align: left;
          text-decoration: underline; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .unactive {
          color: #757c8d; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .phone-number-wrapper {
          margin-top: 20px; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .phone-number-wrapper input.phone-number {
            margin: 0;
            text-align: left;
            font-size: 15px; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .comment-wrapper {
          margin-top: 25px; }
          .get-code-block .get-code-wrapper .get-code-content .get-code-form .address-wrapper .comment-wrapper input.comment {
            margin: 0;
            text-align: left;
            font-size: 15px; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form label {
        display: block;
        font-size: 12px;
        padding: 5px 0; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form select {
        width: 128px;
        padding: 5px 10px;
        border: 1px solid #A8ACB9;
        outline: none !important; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form input {
        width: 252px;
        height: 20px;
        padding: 5px 10px;
        outline: none !important; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form button.get-code {
        margin: 30px auto 0 auto;
        height: 40px;
        width: 110px;
        color: white;
        background: #0288D1;
        border: 0;
        font-size: 15px;
        outline: none;
        cursor: pointer; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form button.get-code:hover {
          background: #0078ba; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form button.second-get-code {
        width: 180px; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form .confirm-block .confirm-header-wrapper {
        font-size: 12px;
        margin-top: 30px; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form .confirm-block .sms-confirm-wrapper {
        margin-top: 30px; }
        .get-code-block .get-code-wrapper .get-code-content .get-code-form .confirm-block .sms-confirm-wrapper input.confirm {
          text-align: left;
          margin: 0; }
      .get-code-block .get-code-wrapper .get-code-content .get-code-form .confirm-block button {
        font-size: 15px; }
  .get-code-block .get-code-wrapper .button-wrapper button {
    height: 40px;
    width: 210px;
    color: white;
    background: #0288D1;
    border: 0;
    font-size: 15px;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 10px;
    float: right; }
    .get-code-block .get-code-wrapper .button-wrapper button:hover {
      background: #0078ba; }

.mobile.get-code-block {
  margin-top: 0; }

.mobile .get-code-wrapper {
  min-height: max-content;
  margin-bottom: 40px; }
  .mobile .get-code-wrapper .get-code-header {
    font-size: 15px;
    margin-bottom: 10px; }
  .mobile .get-code-wrapper .get-code-content {
    width: 350px;
    min-height: 1150px; }
    .mobile .get-code-wrapper .get-code-content .get-code-instruction {
      width: 250px;
      font-size: 12px;
      margin: 0; }
    .mobile .get-code-wrapper .get-code-content .get-code-form {
      min-height: min-content; }
