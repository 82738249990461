@font-face {
  font-family: "Roboto Condensed Ads";
  src: url("../fonts/roboto_condensed/RobotoCondensed-Regular.ttf"); }

body, html {
  background-color: #EDEEF0;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0 !important;
  height: 100%; }

html {
  overflow-y: scroll; }

@-moz-document url-prefix() {
  body {
    font-size-adjust: 0.5; }
  .App-header .links-center li a {
    font-size: 22px !important; } }

#root {
  height: 100%; }

.App {
  display: flex;
  flex-direction: column;
  height: 100%; }

.smarttv-warning {
  text-align: center; }

.upper {
  position: fixed;
  z-index: 5;
  background-image: url(../images/up.png) !important;
  width: 60px;
  height: 60px;
  left: 20px;
  bottom: 20px;
  cursor: pointer; }
  .upper:hover {
    opacity: 0.85; }

.alpha-block {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  filter: alpha(opacity=6);
  opacity: 0.6;
  z-index: 3;
  background-color: #000000; }

.about-page {
  min-height: 800px;
  max-width: 846px;
  margin: -10px auto 0;
  color: #363C4C; }
  .about-page > div {
    margin-bottom: 35px; }
  .about-page ul {
    padding: 0px 0px 0px 17px; }
  .about-page h3 {
    padding: 0 !important; }
  .about-page li {
    margin-bottom: 5px; }
  .about-page a {
    color: #0288D1;
    text-decoration: none;
    font-size: 17px; }
    .about-page a:hover {
      color: #8D94A6; }
  .about-page .about-sensors {
    background: #E1E2E5;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 846px;
    padding: 30px;
    flex-direction: column;
    margin-bottom: 100px; }
    .about-page .about-sensors a {
      color: #0288D1; }

#cl_widget_links {
  top: -15px; }
  #cl_widget_links a {
    font-size: 11px; }

.auth {
  cursor: pointer; }

.content_custom_changes {
  min-height: 0 !important;
  flex: 0 0 auto; }

.content {
  display: flex;
  flex-direction: column; }
  .content .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .content .content-wrapper.publist {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .content .content-wrapper.wide {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .content .content-wrapper .items-grid.right-column {
      width: 365px;
      height: 475px;
      padding-left: 20px;
      margin-top: 0px; }
      .content .content-wrapper .items-grid.right-column .items-grid__item:hover .items-grid__item_arcLink {
        display: block; }
      .content .content-wrapper .items-grid.right-column .items-grid__item {
        display: block;
        float: none;
        margin: 0;
        width: 345px;
        min-height: 80px; }
        .content .content-wrapper .items-grid.right-column .items-grid__item_link .items-name {
          font-size: 16px;
          line-height: 19px;
          color: #363C4C;
          text-align: left;
          position: absolute;
          margin-left: 20px;
          max-width: initial;
          display: initial; }
          .content .content-wrapper .items-grid.right-column .items-grid__item_link .items-name.truba-mode {
            white-space: inherit;
            overflow: inherit;
            text-overflow: inherit; }
        .content .content-wrapper .items-grid.right-column .items-grid__item_link:before {
          content: none; }
        .content .content-wrapper .items-grid.right-column .items-grid__item_link img {
          width: 140px;
          max-height: 78px; }
        .content .content-wrapper .items-grid.right-column .items-grid__item_arcLink {
          position: absolute;
          font-size: 16px;
          line-height: 19px;
          top: 50px;
          margin-left: 160px;
          color: #69738C;
          text-decoration: none;
          text-align: left;
          display: none;
          margin-right: 0; }
          .content .content-wrapper .items-grid.right-column .items-grid__item_arcLink.screen {
            margin-left: 220px; }
          .content .content-wrapper .items-grid.right-column .items-grid__item_arcLink:hover {
            color: #8D94A6; }
    .content .content-wrapper .items-grid {
      list-style: none;
      padding: 0;
      position: relative;
      width: 1171px;
      overflow: hidden;
      min-height: 350px; }
      .content .content-wrapper .items-grid__item {
        display: inline-block;
        float: left;
        list-style: none;
        padding: 0;
        position: relative;
        margin: 0 0 37px 30px;
        width: 370px;
        min-height: 280px;
        float: left; }
        .content .content-wrapper .items-grid__item:nth-child(3n + 1) {
          margin: 0; }
        .content .content-wrapper .items-grid__item_link {
          text-align: left;
          text-decoration: none; }
          .content .content-wrapper .items-grid__item_link .items-name {
            font-size: 20px;
            line-height: 23px;
            color: #363C4C;
            max-width: 370px;
            display: block;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .content .content-wrapper .items-grid__item_link:hover:before {
            opacity: 1;
            background-image: url(../images/play.png);
            background-position: center center;
            background-repeat: no-repeat; }
          .content .content-wrapper .items-grid__item_link:before {
            content: "";
            display: block;
            position: absolute;
            width: 370px;
            height: 210px;
            opacity: 0.35; }
          .content .content-wrapper .items-grid__item_link.decline:before {
            content: "";
            display: block;
            position: absolute;
            width: 370px;
            height: 210px;
            opacity: 0.65 !important;
            background-image: url(../images/sosedDecline.png) !important;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: white;
            border-radius: 5px; }
          .content .content-wrapper .items-grid__item_link.closed:before {
            content: "";
            display: block;
            position: absolute;
            width: 370px;
            height: 210px;
            opacity: 0.65 !important;
            background-image: url(../images/sosedClosed.png) !important;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: white;
            border-radius: 5px; }
          .content .content-wrapper .items-grid__item_link img {
            width: 370px;
            border-radius: 5px;
            margin-bottom: 15px;
            max-height: 209px; }
        .content .content-wrapper .items-grid__item_arcLink {
          font-size: 20px;
          line-height: 23px;
          margin-top: 5px;
          color: #69738C;
          text-decoration: none;
          text-align: left;
          display: inline-block;
          margin-right: 20px; }
          .content .content-wrapper .items-grid__item_arcLink:hover {
            color: #8D94A6; }
        .content .content-wrapper .items-grid__item_outerLink {
          display: block;
          position: relative;
          width: 370px;
          height: 210px;
          background-image: url(../images/cameraAdd.png);
          background-position: center center;
          background-repeat: no-repeat;
          border-radius: 5px;
          margin-bottom: 135px; }
          .content .content-wrapper .items-grid__item_outerLink .informer {
            font-size: 20px;
            line-height: 25px;
            color: #363C4C;
            display: block;
            width: 310px;
            top: 35px;
            left: 30px;
            position: absolute; }
          .content .content-wrapper .items-grid__item_outerLink .informer_no_cameras {
            font-size: 20px;
            line-height: 25px;
            color: #363C4C;
            display: block;
            padding-left: 30px;
            width: 85%;
            top: 35px;
            position: absolute; }
          .content .content-wrapper .items-grid__item_outerLink .button {
            font-size: 20px;
            line-height: 25px;
            color: #ffffff;
            display: block;
            width: 155px;
            top: 140px;
            left: 30px;
            background-color: #0288D1;
            position: absolute;
            border-radius: 3px;
            padding: 8px 0;
            text-align: center; }
            .content .content-wrapper .items-grid__item_outerLink .button:hover {
              background-color: #0078BA; }

.logo-link {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0; }

.App-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.App-header {
  min-width: 1083px;
  min-height: 106px;
  margin: 0 auto;
  position: relative;
  padding-top: 20px; }
  .App-header_top {
    min-height: 84px; }
  .App-header_bottom {
    text-align: center;
    padding: 10px 0px 0px 0px; }
  .App-header .Logo-center {
    width: 242px;
    height: 83px;
    background-image: url(../images/logo.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    position: relative;
    left: 50%;
    margin-left: -130px; }
  .App-header .links {
    padding: 0;
    display: flex;
    margin: 0;
    justify-content: center; }
    .App-header .links li.entrance {
      margin: 0 25px 0 0; }
    .App-header .links li.name {
      margin: 0 10px 0 25px; }
    .App-header .links li {
      display: inline;
      padding: 0 0 15px;
      margin: 0 15px; }
    .App-header .links-left {
      position: absolute;
      top: 0;
      left: 0;
      top: 26px; }
      .App-header .links-left a {
        color: #363C4C;
        text-decoration: none;
        font-size: 20px; }
        .App-header .links-left a:hover {
          color: #757C8D; }
    .App-header .links-right {
      position: absolute;
      right: 0;
      top: 0;
      top: 26px; }
      .App-header .links-right a {
        color: #363C4C;
        text-decoration: none;
        font-size: 20px; }
        .App-header .links-right a:hover {
          color: #757C8D; }
    .App-header .links-center li {
      cursor: pointer; }
      .App-header .links-center li a {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 24px;
        color: #363C4C;
        text-decoration: none;
        padding-bottom: 10px;
        white-space: nowrap; }
        .App-header .links-center li a:hover {
          color: #757C8D; }
        .App-header .links-center li a.active {
          color: #0288D1 !important;
          border-bottom: 4px solid #0288D1;
          font-weight: bold; }
  .App-header .enter-list {
    position: absolute;
    text-align: right;
    width: 320px;
    right: 35px;
    top: 0;
    background: #EDEEF0; }
    .App-header .enter-list_item {
      margin-left: 20px; }

.mobile-header {
  width: 100%;
  min-width: 350px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 23px;
  min-height: 50px; }
  .mobile-header .burger-menu-wrapper {
    margin: 29px auto 30px;
    width: 330px;
    padding: 0 !important;
    margin-bottom: initial !important; }
    .mobile-header .burger-menu-wrapper .menu-burger {
      width: 350px;
      padding: 0 !important;
      min-height: 30px;
      position: relative; }
      .mobile-header .burger-menu-wrapper .menu-burger__active {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        line-height: 30px;
        color: #0387d1;
        font-size: 25px;
        font-family: 'Roboto', sans-serif; }
      .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper {
        position: absolute;
        display: block; }
        .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper__display-items {
          display: contents; }
        .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn {
          display: inline-block;
          cursor: pointer;
          height: 30px;
          width: 30px;
          margin-right: 14px; }
          .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn .bar1, .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn .bar2, .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn .bar3 {
            width: 30px;
            height: 3px;
            background-color: #0387d1;
            margin: 5px 0;
            transition: 0.4s; }
          .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change {
            display: block;
            position: absolute;
            left: 5px; }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change .bar1, .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change .bar2, .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change .bar3 {
              width: 30px;
              height: 3px; }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change .bar1 {
              -webkit-transform: rotate(-45deg) translate(-9px, 6px);
              transform: rotate(-45deg) translate(-8px, 3px); }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change .bar2 {
              opacity: 0; }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-btn.change .bar3 {
              -webkit-transform: rotate(45deg) translate(-8px, -8px);
              transform: rotate(45deg) translate(-8px, -4px); }
        .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items {
          width: 300px;
          padding: 0 !important;
          margin-left: 34px; }
          .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items__wrap:first-child {
            padding-top: 3px; }
          .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items__wrap {
            padding: 10px; }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items__wrap a {
              font-family: 'Roboto', sans-serif;
              font-size: 25px !important;
              text-align: left;
              color: #2481B5; }
          .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items a {
            text-decoration: none;
            display: block;
            color: #363c4c;
            vertical-align: center; }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items a.active {
              color: #0288d1;
              font-size: 26px;
              width: max-content; }
            .mobile-header .burger-menu-wrapper .menu-burger .left-wrapper .burger-items a:hover {
              border-bottom-color: #69738c;
              border-style: solid;
              border-top: none;
              border-left: none;
              border-right: none;
              width: max-content; }
  .mobile-header .enter-list {
    position: absolute;
    text-align: right;
    width: 290px;
    right: 20px;
    top: 0;
    background: #EDEEF0; }
    .mobile-header .enter-list_item:first-child {
      margin-right: 20px; }
  .mobile-header_top {
    height: 42px;
    border-bottom: 1px solid #C6CDDB;
    position: relative; }
  .mobile-header-logo {
    width: 195px;
    height: 68px;
    background-image: url(../images/logo.svg);
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    margin: 10px auto 0;
    background-size: contain; }
  .mobile-header_bottom {
    width: 100%;
    min-width: 350px;
    margin-top: 23px;
    text-align: center; }
  .mobile-header .links {
    list-style: none;
    padding: 0;
    margin: 0; }
    .mobile-header .links a {
      text-decoration: none;
      color: #363C4C; }
      .mobile-header .links a:hover {
        color: #757C8D; }
    .mobile-header .links.links-left {
      line-height: 42px;
      float: left;
      margin-left: 20px; }
    .mobile-header .links.links-right {
      line-height: 42px;
      float: right;
      margin-right: 20px; }
      .mobile-header .links.links-right li {
        display: inline; }
        .mobile-header .links.links-right li.name {
          margin: 0 10px 0 25px; }
    .mobile-header .links.links-center li {
      display: inline-block; }
      .mobile-header .links.links-center li:first-child {
        margin-right: 9px; }
      .mobile-header .links.links-center li a {
        width: 170px;
        height: 40px;
        display: block;
        text-align: center;
        background: #E4E5E8;
        border-radius: 3px;
        color: #69738C;
        cursor: pointer;
        font-size: 16px;
        line-height: 40px;
        white-space: nowrap; }
        .mobile-header .links.links-center li a.active {
          background: #0288D1;
          color: #FFFFFF; }

.mobile-wrapper {
  margin: 29px auto 30px;
  width: 350px;
  padding: 0 !important;
  margin-bottom: initial !important; }
  .mobile-wrapper img {
    width: 95%;
    max-width: 350px; }
  .mobile-wrapper .items-grid {
    margin: 0;
    padding: 0;
    text-align: center; }
    .mobile-wrapper .items-grid__item {
      list-style: none;
      margin: 0;
      padding: 0;
      border-radius: 3px; }
      .mobile-wrapper .items-grid__item_link {
        text-align: left;
        text-decoration: none; }
        .mobile-wrapper .items-grid__item_link .items-name {
          font-size: 20px;
          line-height: 23px;
          color: #363C4C;
          width: 100%;
          max-width: 350px;
          display: block;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0 auto; }
        .mobile-wrapper .items-grid__item_link img {
          width: 370px;
          border-radius: 5px;
          margin-bottom: 15px;
          max-height: 209px; }
      .mobile-wrapper .items-grid__item_arcLink {
        font-size: 20px;
        line-height: 23px;
        color: #69738C;
        text-decoration: none;
        text-align: left;
        display: block;
        width: 95%;
        max-width: 350px;
        margin: 5px auto 20px;
        cursor: pointer; }
        .mobile-wrapper .items-grid__item_arcLink.screen {
          display: none; }
          .mobile-wrapper .items-grid__item_arcLink.screen.display {
            display: inline-block; }
        .mobile-wrapper .items-grid__item_arcLink:hover {
          color: #8D94A6; }
      .mobile-wrapper .items-grid__item_outerLink {
        display: block;
        position: relative;
        max-width: 350px;
        width: 95%;
        height: 205px;
        background-image: url(../images/cameraAdd.png);
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 5px;
        margin: 0 auto 0px;
        background-size: cover; }
        .mobile-wrapper .items-grid__item_outerLink .informer {
          font-size: 20px;
          line-height: 25px;
          color: #363C4C;
          display: block;
          width: 275px;
          top: 25px;
          left: 30px;
          position: absolute; }
        .mobile-wrapper .items-grid__item_outerLink .informer_no_cameras {
          font-size: 20px;
          line-height: 25px;
          color: #363C4C;
          display: block;
          width: 275px;
          top: 25px;
          left: 30px;
          position: absolute; }
        .mobile-wrapper .items-grid__item_outerLink .button {
          font-size: 20px;
          line-height: 25px;
          color: #ffffff;
          display: block;
          width: 155px;
          top: 130px;
          left: 50%;
          margin-left: -75px;
          background-color: #0288D1;
          position: absolute;
          border-radius: 3px;
          padding: 8px 0;
          text-align: center; }
          .mobile-wrapper .items-grid__item_outerLink .button:hover {
            background-color: #0078BA; }

.loading,
.error {
  text-align: center;
  display: block;
  width: 100%;
  height: 100px;
  margin-bottom: 15px; }

.loading {
  background-image: url(../images/loading.gif);
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 50px; }
  .loading.map-loading {
    position: absolute;
    top: 170px;
    z-index: 1; }

.no-cameras {
  display: block;
  position: relative;
  width: 370px;
  height: 210px;
  visibility: hidden;
  margin-bottom: 37px; }

@supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
  .content-text__upper {
    font-size: 17px !important; }
  .share_video_name {
    position: absolute; } }

.horizontal-banner .slick-dots {
  bottom: -6px !important; }

.slick-list {
  padding: 0 0 0 1% !important; }

.about-top-list {
  width: 100%;
  height: 131px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .about-top-list.citylink {
    background-image: url(../images/trubaBack.png); }
  .about-top-list > div {
    max-width: 828px;
    text-align: center; }
  .about-top-list p {
    color: #FFFFFF;
    font-size: 17px;
    line-height: 27px;
    padding-left: 0px; }

.blinking_label {
  animation: blink 1s linear infinite; }

@keyframes blink {
  0% {
    color: black;
    background: none;
    opacity: .2; }
  50% {
    opacity: .6;
    background: red; }
  100% {
    opacity: 1;
    color: red;
    background: rgba(255, 0, 0, 0); } }

.camera-bottom_block {
  margin: 40px 0 40px 0;
  max-width: 775px;
  border-radius: 5px; }

.camera-ads_block {
  width: 98%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #E4E5E8; }

.camera-ads_block-text {
  color: #363C4C;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto Condensed Ads", sans-serif; }

.camera-ads_block-button {
  border: none;
  border-radius: 3px;
  width: 200px;
  height: 50px;
  background: #0288D1;
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto Condensed Ads", sans-serif;
  cursor: pointer; }

.footer-block {
  width: 100%;
  height: 80%;
  margin-bottom: 20px; }

.footer-ads_block {
  height: 100%;
  width: 98%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #E4E5E8; }

.footer-ads_block-text {
  color: #363C4C;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto Condensed Ads", sans-serif; }

.footer-ads_block-button {
  border: none;
  border-radius: 3px;
  width: 200px;
  height: 45px;
  background: #0288D1;
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto Condensed Ads", sans-serif;
  cursor: pointer; }
