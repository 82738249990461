.vjs-moidom-skin .vjs-big-play-button {
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-image: url(../images/play.png);
  outline: 0 !important; }
  .vjs-moidom-skin .vjs-big-play-button .vjs-icon-placeholder {
    display: none; }

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
  background-image: url(../images/pause.png); }
  .vjs-paused.vjs-has-started .vjs-big-play-button:hover {
    background-image: url(../images/play.png); }

.vjs-moidom-skin.noAudio .vjs-volume-panel {
  display: none; }

.video-player {
  position: relative;
  min-height: 650px; }
  .video-player.single {
    max-width: 770px; }
  .video-player.archive {
    margin-bottom: 120px;
    min-height: initial;
    min-width: 1170px; }
    .video-player.archive .vjs-moidom-skin {
      display: inline-block; }
    .video-player.archive .video_and_promo {
      display: flex;
      flex-direction: row; }
  .video-player.code {
    margin-bottom: 30px;
    min-height: initial; }
    .video-player.code:last-child {
      margin-bottom: 120px;
      padding-bottom: 7em; }
  .video-player-info {
    margin-top: 20px; }
    .video-player-info .video-player-counters {
      display: block;
      margin: 10px 0; }
      .video-player-info .video-player-counters span {
        display: inline-block;
        font-size: 16px;
        line-height: 19px;
        margin-right: 20px;
        color: #69738C; }
    .video-player-info .video-player-links {
      margin-bottom: 20px;
      position: relative; }
      .video-player-info .video-player-links .social-wrapper {
        position: absolute;
        top: 3px;
        left: 240px; }
        .video-player-info .video-player-links .social-wrapper__item {
          display: inline-block;
          margin-right: 20px;
          outline: none;
          cursor: pointer; }
          .video-player-info .video-player-links .social-wrapper__item:hover {
            opacity: 0.85; }
    .video-player-info .video-player_descr {
      font-size: 16px;
      color: #363C4C;
      margin-bottom: 60px;
      padding-bottom: 30px;
      max-width: 770px; }
      .video-player-info .video-player_descr.pdd {
        max-width: 100%; }
      .video-player-info .video-player_descr .public-link {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 0 15px;
        color: #69738C;
        text-align: left;
        display: inline-block;
        cursor: pointer; }
        .video-player-info .video-player_descr .public-link:last-child {
          margin-bottom: 20px; }
        .video-player-info .video-player_descr .public-link:hover {
          color: #8D94A6; }
  .video-player_name {
    display: block;
    font-size: 24px;
    line-height: 28px;
    color: #363C4C;
    text-align: left; }
    .video-player_name h1 {
      font-size: 100%;
      font-weight: normal; }
  .video-player_link {
    font-size: 20px;
    line-height: 23px;
    margin: 10px 30px 0 0;
    color: #69738C;
    text-decoration: none;
    text-align: left;
    display: inline-block;
    cursor: pointer; }
    .video-player_link:hover {
      color: #8D94A6; }
  .video-player-decline, .video-player-closed, .video-player-waiting {
    font-size: 16px;
    color: #0288D1;
    margin: 5px 0 10px;
    display: block; }
  .video-player-decline {
    color: red; }
  .video-player .btn {
    border: #0288D1 1px solid;
    border-radius: 10px;
    margin: 5px;
    padding: 5px 10px;
    color: #0288D1;
    font-weight: 500;
    font-size: 16px; }
    .video-player .btn.btn-red {
      color: red;
      border-color: red; }

.mobile-video-player {
  margin: 0 auto 0px;
  width: 350px;
  position: relative;
  height: 300px; }
  .mobile-video-player.archive:last-child {
    margin-bottom: 140px; }
  .mobile-video-player .video-player_name {
    display: block;
    font-size: 20px;
    line-height: 23px;
    color: black;
    text-align: left; }
  .mobile-video-player .video-player_link {
    font-size: 20px;
    line-height: 23px;
    margin: 5px 20px 0 0;
    color: #69738C;
    text-decoration: none;
    text-align: left;
    display: inline-block;
    cursor: pointer; }
    .mobile-video-player .video-player_link:hover {
      color: #8D94A6; }

.video-comments-wrapper {
  margin: -40px 0px 60px;
  max-width: 770px; }
  .video-comments-wrapper .video-comments-header {
    color: #363C4C;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 30px; }
  .video-comments-wrapper .comment {
    font-size: 16px;
    margin-bottom: 20px; }
    .video-comments-wrapper .comment-name {
      display: inline-block;
      color: #69738C;
      margin-right: 20px; }
    .video-comments-wrapper .comment-date {
      display: inline-block;
      color: #69738C; }
    .video-comments-wrapper .comment-text {
      width: 100%;
      color: #363C4C;
      margin-top: 7px;
      display: block; }
  .video-comments-wrapper .comment-add-wrapper {
    margin-top: 45px;
    position: relative;
    height: 160px;
    max-width: 770px; }
    .video-comments-wrapper .comment-add-wrapper .empty {
      border-color: red !important; }
    .video-comments-wrapper .comment-add-wrapper .error-comment {
      color: red;
      margin-top: 20px;
      display: block;
      position: absolute; }
    .video-comments-wrapper .comment-add-wrapper input {
      height: 20px;
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 5px;
      border: 0;
      border-bottom: 2px solid #69738C;
      background: none;
      outline: 0; }
    .video-comments-wrapper .comment-add-wrapper button {
      font-size: 20px;
      color: white;
      width: 180px;
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      background: #0288D1;
      border-radius: 3px;
      border: 0;
      cursor: pointer;
      display: block;
      float: right;
      outline: 0; }
      .video-comments-wrapper .comment-add-wrapper button:hover {
        background: #0078BA; }
    .video-comments-wrapper .comment-add-wrapper #commentName {
      width: 160px;
      margin-right: 40px; }
    .video-comments-wrapper .comment-add-wrapper #commentText {
      width: 565px; }

.video-wrapper {
  display: contents; }
