.popup-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0; }
  .popup-mobile .popup-mobile-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    .popup-mobile .popup-mobile-blur_1 {
      background-color: white; }
    .popup-mobile .popup-mobile-blur_2 {
      background-color: rgba(39, 35, 35, 0.22);
      opacity: 0.9; }
  .popup-mobile .popup-mobile-body {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: start;
    justify-content: center; }
  .popup-mobile .popup-mobile-img {
    top: 70px;
    position: absolute; }
  .popup-mobile__btn-close {
    position: absolute;
    z-index: 10;
    left: calc(100vw - 40px);
    top: 10px;
    background-image: url("../images/icons/i-popup-close-black.svg");
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer; }
    .popup-mobile__btn-close_2 {
      top: 30px; }
  .popup-mobile__content-text {
    z-index: 10;
    margin-top: 40px;
    width: 100%;
    margin-left: 30px;
    scale: 0.8; }
    .popup-mobile__content-text__header {
      font-size: 40px;
      line-height: 0.9; }
    .popup-mobile__content-text__additional-text {
      color: #393939;
      font-size: 17px; }
    .popup-mobile__content-text__benefits {
      width: 25px;
      font-weight: bold; }
      .popup-mobile__content-text__benefits__img {
        vertical-align: middle;
        padding-right: 8px; }
    .popup-mobile__content-text__btns {
      z-index: 10;
      margin-top: 30px; }
      .popup-mobile__content-text__btns__btn-send-request {
        width: 200px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        transition: .3s;
        color: white;
        font-size: 20px;
        font-weight: 700;
        text-align: center; }
        .popup-mobile__content-text__btns__btn-send-request_2 {
          background: #075cff; }
          .popup-mobile__content-text__btns__btn-send-request_2:hover {
            background: #044ed8; }
      .popup-mobile__content-text__btns__btn-pass {
        width: 200px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        transition: .3s;
        color: #075cff;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        border-width: 0.5px;
        border-color: #075cff; }
        .popup-mobile__content-text__btns__btn-pass_2 {
          background: #fcfdff; }
          .popup-mobile__content-text__btns__btn-pass_2:hover {
            background: #044ed8; }
  .popup-mobile__btn-download {
    position: absolute;
    left: calc(50vw - 105px);
    bottom: 40px;
    z-index: 10;
    width: 210px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: .3s;
    color: white;
    font-size: 30px;
    font-weight: 700;
    text-align: center; }
    .popup-mobile__btn-download_1 {
      background: #ff0055; }
      .popup-mobile__btn-download_1:hover {
        background: #e4004b; }
    .popup-mobile__btn-download_2 {
      background: #ff6a00; }
      .popup-mobile__btn-download_2:hover {
        background: #e66000; }
