.notification-block {
  text-align: center;
  width: 100%;
  margin: 0 auto; }
  .notification-block .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem; }
  .notification-block .errors-block .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .notification-block .success-block .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
