.attention-popup-transition-enter {
  opacity: 0.01; }

.attention-popup-transition-enter.attention-popup-transition-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.attention-popup-transition-leave {
  opacity: 1; }

.attention-popup-transition-leave.attention-popup-transition-leave-active {
  opacity: 0.01;
  transition: opacity .5s ease-in-out; }

.attention-popup-transition-appear {
  opacity: 0.01; }

.attention-popup-transition-appear.attention-popup-transition-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.attention-popup-modal {
  color: #69738C;
  position: fixed;
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  z-index: 5;
  font-family: 'Roboto Condensed', sans-serif; }
  .attention-popup-modal .balloon-close {
    background-size: 25px;
    font-size: 24px;
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    background-image: url(../images/close.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    top: 20px;
    right: 20px; }
  .attention-popup-modal .outer:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .attention-popup-modal .inner {
    display: inline-block;
    vertical-align: middle; }
    .attention-popup-modal .inner span {
      display: block; }
    .attention-popup-modal .inner .text-first {
      margin-bottom: 10px; }
  .attention-popup-modal .outer {
    text-align: center; }

div.browser-popup {
  top: 13rem;
  left: 25rem;
  font-size: 24px; }
  div.browser-popup .outer {
    height: 200px;
    width: 500px; }

div.mobile-popup {
  top: 15rem;
  left: 0.6rem;
  font-size: 20px; }
  div.mobile-popup .outer {
    height: 200px;
    width: 370px; }
