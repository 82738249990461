.buy-access-modal {
    .archive-modal__content {
        height: 400px;

        @media (max-width: 576px) {
            padding: 30px;
            height: auto;
        }
    }

    &__title {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 30px;
        line-height: 120%;
        text-align: center;
        color: #363C4C;
        margin: 0 0 36px;

        @media (max-width: 576px) {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 20px;
        }
    }

    &__text {
        font-family: "Roboto Condensed", sans-serif;
        font-style: normal;
        font-size: 24px;
        font-weight: 400;

        max-width: 850px;
        margin: 0 0 50px;
        line-height: 36px;

    }

    &__price-text {
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #363C4C;
        margin: 0 0 40px;

        @media (max-width: 576px) {
            font-size: 20px;
            line-height: 23px;
            margin: 30px 0 40px;
        }
    }
}