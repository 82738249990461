.balance__error {
  text-align: center;
  display: block;
  margin-top: 20%;
  color: #0288D1 !important;
  font-size: 3em;
  font-family: 'Roboto Condensed', sans-serif; }

.mobile-cameras {
  margin-top: 15px; }

.mobile-wrapper.nearby_cameras {
  padding-bottom: 2em !important; }
  .mobile-wrapper.nearby_cameras .items-grid .items-grid__item:last-child {
    margin-bottom: 170px; }
