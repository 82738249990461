.pdd {
  position: relative;
  margin-bottom: 25px; }
  .pdd .pdd-ava span {
    width: 80px;
    height: 80px;
    display: block;
    background-position: center;
    border-radius: 40px; }
  .pdd .pdd-name {
    position: absolute;
    top: 0;
    left: 120px;
    color: #69738C;
    font-size: 16px; }
    .pdd .pdd-name span {
      display: block;
      color: #363C4C;
      font-size: 18px;
      line-height: 23px;
      padding: 8px 0; }
    .pdd .pdd-name a {
      text-decoration: none;
      font-size: 18px;
      color: #69738C;
      height: 30px;
      display: block;
      line-height: 30px;
      padding-left: 40px; }
      .pdd .pdd-name a.ideal {
        background-image: url(../images/dtp/school_1.png);
        background-repeat: no-repeat;
        background-position: left center; }
      .pdd .pdd-name a.klakson {
        background-image: url(../images/dtp/school_2.png);
        background-repeat: no-repeat;
        background-position: left center; }
      .pdd .pdd-name a:hover {
        color: #8D94A6; }
