body, html {
  background-color: #EDEEF0;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0 !important;
  height: 100%; }

.top-info-banner {
  width: 100%;
  height: 100%;
  min-width: 1362px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .top-info-banner.citylink {
    background-image: url(../images/sensorsBack.png); }
  .top-info-banner p {
    color: #000;
    font-size: 15px; }
  .top-info-banner > div.top-info-banner_inner {
    max-width: 1103px;
    display: flex;
    justify-content: center; }
  .top-info-banner .colapse {
    height: 50px;
    width: 68px;
    color: #363C4C;
    text-align: left;
    cursor: pointer;
    background-image: url(../images/Vector.png);
    background-repeat: no-repeat;
    background-position: 0px 28px; }
    .top-info-banner .colapse.open {
      background-image: url(../images/VectorReverse.png); }
    .top-info-banner .colapse:hover {
      color: #69738C; }

.sensor a {
  border-bottom: 1px dashed #363C4C; }

.sensors {
  display: flex;
  justify-content: space-around; }
  .sensors > div {
    display: flex;
    flex-direction: column; }

.sensors > div small {
  font-size: 14px;
  color: #8d94a6; }

.humidity {
  background: url(../images/sensors/humidity.png) center center no-repeat;
  height: 140px;
  width: 140px; }

div.humidity__text, div.temperature__text {
  color: #0288D1;
  text-align: center;
  font-weight: bold; }

.temperature {
  background: url(../images/sensors/temperature.png) center center no-repeat;
  margin-right: 20px;
  height: 140px;
  width: 73px; }

.sensor-wrapper {
  margin: 0 auto;
  width: 1000px;
  text-align: center; }

.sensor-wrapper .plot-wrapper {
  display: block;
  width: 100%;
  text-align: center; }
