.page {
  padding: 0;
  margin: 0;
  font-size: 12pt;
  color: #363C4C; }

h2 {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 24px; }

h3 {
  text-align: center;
  padding: .5cm 0; }

.page {
  margin: 0 auto 60px;
  position: relative;
  padding: 20px 5px 80px 5px; }

.page-footer {
  position: absolute;
  bottom: 1cm;
  left: 0;
  right: 0;
  opacity: .5; }

.page-footer-content {
  padding-left: 3cm;
  padding-right: 1.5cm; }

.logo {
  width: 8cm;
  opacity: .5; }

.fl-r {
  float: right; }

.fl-l {
  float: left; }

.ta-r {
  text-align: right; }

.ta-l {
  text-align: left; }

.ta-c {
  text-align: center; }

.ta-j {
  text-align: justify; }

.bold {
  font-weight: bold; }

.black-line {
  border: 1px solid black; }

.list {
  counter-reset: li;
  list-style: none; }

.list li:before {
  counter-increment: li;
  content: counters(li, ".") ".";
  margin-right: .3cm; }

.list.no-header {
  counter-reset: li 1; }

.list.no-header > li:first-child:before {
  display: none; }

.list-header {
  text-align: center;
  font-weight: bold;
  margin: .5cm 0; }

.sublist {
  margin-top: .5cm;
  font-weight: normal; }

.paragraph {
  text-align: justify;
  text-indent: 1cm; }

.small {
  font-size: 10pt; }

.w100 {
  width: 100%; }

hr {
  margin: 2px 0; }

.mobile-wrapper h2 {
  text-align: left; }

.mobile-wrapper .fl-r {
  float: none; }
