.simple_background {
  width: 100%;
  position: fixed;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: xxx-large;
  user-select: none;
  transition-duration: 5s;
  z-index: 10; }
